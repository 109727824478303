var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-sparkline", {
        attrs: {
          gradient: ["#f72047", "#ffd200", "#1feaea"],
          "line-width": 2,
          padding: 8,
          smooth: 10,
          value: _vm.get_chart_value,
          "auto-draw": "",
          "gradient-direction": "top",
          "stroke-linecap": "round",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }